import "./Home.css"
import React, { useRef } from 'react';
import Splash from '../../components/Splash/Splash';

import { scrollTo } from '../../utils/scrollTo'

// SLIDES
import WelcomeSlide from "./Slides/WelcomeSlide";
import HistorySlide from "./Slides/HistorySlide";
import ServiceSlide from "./Slides/ServiceSlide";
import CatalogueSlide from './Slides/CatalogueSlide'
import ContactSlide from "./Slides/ContactSlide";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const Home = () => {


    const storyRef = useRef()
    const serviceRef = useRef()
    const catalogueRef = useRef()
    const contactRef = useRef()


    return (
        <div>
            <Splash isLoading={false} />



            <WelcomeSlide callback={() => scrollTo({ref: storyRef, duration: 1000})}>
                <Navbar 
                    scrollTo={scrollTo}     
                    storyRef={storyRef}
                    serviceRef={serviceRef}
                    catalogueRef={catalogueRef}
                    contactRef={contactRef}
                />
            </WelcomeSlide >

            <HistorySlide ref={storyRef}/>
            <ServiceSlide ref={serviceRef} />
            <CatalogueSlide ref={catalogueRef} />
            <ContactSlide ref={contactRef } />


        </div>
    )
};

export default Home;
