import './App.css';
import { Routes, Route } from 'react-router-dom'
import Home from './screens/Home/Home';


function App() {
  return (
    <div className="">
      <Routes>
        <Route path='/' element={<Home />} />

      </Routes>
    </div>
  );
}

export default App;
