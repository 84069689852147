import "./splash.css"
import React from 'react';
import logo from '../../assets/logo/logo_white.svg'

const Splash = (props) => {

    var classVar = props.isLoading ? "" : "disappear "

    return (
        <div className={`${classVar} splash-container flex justify-center items-center`}>
            <div className='background-container animate-pulse' />
            <div className='logo-container w-40 animate-pulse'>
                <img alt="logo" src={logo} />
            </div>
        </div>
    )
} 

Splash.defaultProps = {
    isLoading: true
}

export default Splash;
