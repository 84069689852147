import React from 'react'
import ReactCardCarousel from 'react-card-carousel';

import "./Services.css"

const Services = () => {

    return (
        <div className='flex-1 flex flex-col justify-center items-center'>

            <div>
                <ReactCardCarousel autoplay={true} autoplay_speed={5000}>

                    <CarouselItem
                        title="Affilatura Professionale"
                        subtitle="L'Affilatura e' il primo punto di forza di una buona Arrotineria.
                    Ci prendiamo cura dei tuoi utensili da lavoro, come per esempio Forbici per Sarti 
                    e Parrucchieri, oltre che ovviamente dei tuoi Coltelli. 
                    "
                    />
                    <CarouselItem
                        title="Vendita e Assistenza"
                        subtitle="Oltre che dedicarci alla vendita delle principali Attrezzature da lavoro,
                    ci occupiamo anche dell'assistenza e della manutenzione di questi ultimi, come per esempio
                    di Affettatrici, Tritacarne, Sottovuoto, Tosatrici, etc."
                    />
                    <CarouselItem
                        title="Rettifica e Piallatura"
                        subtitle="La rettifica e la Piallatura sono dei processi molto delicati, saperli esegurie al meglio
                    fa parte di esperienza, noi lo facciamo in modo impeccabile. Disponibilita' di 
                    rettifica e piallatura per Ceppi in Legno e Polietilene
                    "
                    />
                    <CarouselItem title="Sistemi Espositivi"
                        subtitle="I sistemi espositivi sono una parte importante di ogni attivita' di successo,
                    anche l'occhio vuole la sua parte, ed e' importante presentare al meglio i propri prodotti,
                    per questo selezioniamo i migliori sistemi espositivi sul mercato e li portiamo a voi."
                    />

                </ReactCardCarousel>
            </div>
        </div>
    )
}

const CarouselItem = ({ title, subtitle }) => {
    return (
        <div className='bg-white caros-item p-6 md:p-10 rounded cursor-pointer'>
            <p className='text-2xl md:text-2xl 2xl:text-4xl avgarde mb-6'>{title}</p>
            <p className='text-sm md:text-lg 2xl:text-xl avgarde-thin'>{subtitle}</p>
        </div>
    )
}

export default Services