import "./brands.css"
import React from 'react'

import victorinox from '../../assets/imgs/brands/victorinox.png'
import kai from '../../assets/imgs/brands/kai.png'
import bufalo from '../../assets/imgs/brands/bufalo.png'

const Brands = () => {

    let brands = [
        {
            label: 'victorinox',
            img: victorinox
        },
        {
            label: 'kai',
            img: kai
        },
        {
            label: 'bufalo',
            img: bufalo
        }
    ]


    const handleBrandRender = (brand, key) => {
        return  <img key={key} className="h-20" src={brand.img} />
    }

    return (
        <div className='mb-10  md:block'>
            <div className='max-w-4xl mx-auto'>
                <p className='avgarde text-center text-white text-3xl mb-2'>ALCUNI DEI NOSTRI MARCHI</p>
            </div>

            <div className='max-w-4xl relative slider-container rounded flex items-center bg-white overflow-hidden mx-2 md:mx-auto '>
                <div className='slider-left' />

                <div className='slider flex-row flex-1 flex justify-around'>
                    {brands.map(handleBrandRender)}
                </div>

                <div className='slider-right' />
            </div>
        </div>
    )
}

export default Brands