import React, { forwardRef } from 'react'
import smcv from '../../../assets/imgs/photos/smcv.jpg'
import Fade from 'react-reveal/Fade'

const HistorySlide = forwardRef((props, ref) => {

    let subtitle = "L'Arrotineria Baldassarre nasce nel 1989 a Santa Maria Capua Vetere, storica città in provincia di Caserta. Dal giorno in cui abbiamo aperto, cerchiamo ogni giorno di soddisfare a pieno i bisogni dei nostri clienti con passione e professionalità, ogni giorno."


    return (
        <div ref={ref} className='h-screen relative text-white bg-black'>
            <div className=' max-w-7xl pt-16 md:pt-48 mx-auto flex flex-col md:flex-row text-right'>
                <Fade>
                    <div
                        style={{ background: `url(${smcv})`, backgroundPosition:'center'}} 
                        className='mx-auto bg-right md:bg-center w-96 h-72 md:w-1/2 md:h-96  vignette-inset'>

                    </div>
                </Fade>
                <Fade right>
                    <div className='p-6 md:p-2 md:pl-10 flex flex-col items-end flex-1 flex-wrap'>
                        <p className='text-4xl lg:text-5xl avgarde z-20'>30 ANNI DI ESPERIENZA</p>
                        <p className='avgarde-thin text-lg lg:text-2xl z-20'>{subtitle}</p>
                    </div>
                </Fade>
            </div>
        </div>
    )
})

export default HistorySlide