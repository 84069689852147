import React, { useState } from 'react'
import { Fade } from 'react-reveal'
import emailjs, { init } from '@emailjs/browser'


init("user_V7rrj9EmaJ5B9XieUXc3u");

const ContactForm = () => {


    const [state, setState] = useState({
        fromName: '',
        messageBody: '',
        fromEmail: '',
        sent: false
    })


    let templateID = "template_4l6bjui"
    let serviceID = "service_uyyxdez"

    const handleClick = (e) => {

        if (state.fromName.length <= 2 || state.fromEmail.length <= 2 || state.messageBody.length <= 2) {
            alert("Compila tutti i Campi per piacere")
        } else {
            emailjs.send(serviceID, templateID,
                {
                    from_name: state.fromName,
                    message: (state.messageBody + "- EMAIL: " + state.fromEmail)
                })
                .then(res => {
                    setState({ ...state, sent: true })
                })
                .catch(err => {
                    console.log('FAILED...', err);
                })
        }
    }


    return (
        <>
            <Fade left >
                <p className='z-40 text-5xl avgarde text-white '>Rimaniamo in Contatto.</p>
                <p className='text-xl flex flex-wrap max-w-lg text-white avgarde-thin'>Sentiti libero di scriverci per qualsiasi cosa, prenderemo in carico la tua richiesta appena possibile</p>

                <p className='text-white avgarde text-3xl mt-6 mb-2 '>Come ti chiami?</p>
                <input
                    required
                    value={state.fromName}
                    onChange={(e) => setState({ ...state, fromName: e.target.value })}
                    className='w-full lg:w-1/2 outline-none rounded-sm p-2 avgarde'
                    placeholder="Inserisci il tuo nome"
                />

                <p className='text-white avgarde text-3xl mt-6 mb-2 '>Dove possiamo ricontattarti?</p>
                <input
                    required
                    value={state.fromEmail}
                    onChange={(e) => setState({ ...state, fromEmail: e.target.value })}
                    className='w-full lg:w-1/2 outline-none rounded-sm p-2 avgarde'
                    placeholder="Inserisci Email o un Tel."
                />

                <p className='text-white avgarde text-3xl mt-6 mb-2 '>Cosa hai da dirci?</p>
                <textarea
                    required
                    value={state.messageBody}
                    onChange={(e) => setState({ ...state, messageBody: e.target.value })}
                    placeholder="Inserisci qui il tuo messaggio"
                    className='outline-none p-2 rounded w-full lg:w-1/2'
                />

                {
                    state.sent
                        ? <p className='mt-4 text-center max-w-sm avgarde  text-2xl text-black p-1 border-2 border-white bg-white transition-all'>MESSAGGIO INVIATO</p>
                    : (<p
                            onClick={handleClick}
                            className='mt-4 text-center max-w-sm cursor-pointer avgarde text-white text-2xl hover:text-black p-1 border-2 border-white hover:bg-white transition-all'>
                            INVIA MESSAGGIO
                        </p>)
                }

            </Fade>
        </>
    )
}

export default ContactForm