import React, { useState, useRef, forwardRef } from 'react'

const ContactSlide = forwardRef((props,ref ) => {

    return (
        <div ref={ref} className='h-screen bg-black pt-10'>
            <div className='max-w-6xl mx-auto flex flex-col items-center justify-center'>
                <p className='text-white text-4xl avgarde'>IL NOSTRO CATALOGO</p>


                <div className='mt-4 p-4 '>
                    <iframe
                        src="//v.calameo.com/?bkcode=0068488739b99cc5e440d"
                        className=''
                        height="500"
                        frameBorder="0"
                        scrolling="no"
                        allowtransparency
                        allowFullScreen
                    />
                </div>

            </div>
        </div >
    )
})

export default ContactSlide