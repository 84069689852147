import React, { forwardRef } from 'react'
import contactBg from '../../../assets/imgs/photos/4.jpg'
import ContactForm from '../../../components/ContactForm/ContactForm'

const ContactSlide = forwardRef((props,ref) => {
  return (
    <div ref={ref} className='min-h-screen relative py-40'>
      {/* GRADIENT TOP */}
      <div className='gradient-black z-10 h-40 absolute top-0 left-0 right-0' />
      {/* GRADIENT TOP */}

      <div className='lg:max-w-6xl flex flex-col md:flex-row mx-auto'>
        <div className='flex-1 p-4'>
          <ContactForm />
        </div>
        <div className='flex-1 flex flex-col items-center justify-center'>
          <a href="https://www.facebook.com/coltelleriabaldassarre" className='text-3xl text-white avgarde cursor-pointer transition'>Seguici su <span className='hover:text-[#3b71e3]' >Facebook</span></a>
          <a href="http://instagram.com/arrotineriabaldassarre" className='text-3xl text-white avgarde cursor-pointer transition'>Seguici su <span className='hover:text-[#E1306C]' >Instagram</span></a>
          <br />
          <a href="https://wa.me/+393394795825" className='text-3xl text-white avgarde cursor-pointer transition'>Contattaci su <span className='hover:text-[#28f762]' >WhatsApp</span></a>
        </div>
        
      </div>


      {/* BACKGROUND + OVERLAY */}
      <div style={{ backgroundImage: `url(${contactBg}` }} className='bg absolute inset-0 opacity-30 -z-10' />
      <div className='bg-black absolute inset-0 -z-20' />
    </div>
  )
})

export default ContactSlide