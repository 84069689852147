import React from 'react'

// COMPONENTS
import Brands from "../../../components/Brands/Brands";
import Navbar from '../../../components/Navbar/Navbar'

import backgroundImg from '../../../assets/imgs/2.jpg'



const WelcomeSlide = (props) => {


    return (
        <div className='h-screen w-full relative overflow-hidden'>
            {/* GRADIENT TOP */}
            <div className='gradient-black h-40 absolute top-0 left-0 right-0' />
            {/* GRADIENT TOP */}

            {props.children}

            <div className='max-w-5xl mx-auto text-white z-20 p-2 md:p-0'>
                <h1 className='mt-10 text-3xl lg:text-5xl avgarde z-20'>Arrotineria Baldassarre</h1>
                <p className='avgarde-thin text-xl lg:text-2xl z-20'>Dal 1989 ci prendiamo cura dei tuoi coltelli.</p>
            </div>

            <div
                onClick={props.callback}
                className='text-center cursor-pointer left-0 right-0 max-w-xl mx-auto text-white absolute bottom-10'
            >
                <Brands />
                <p className='text-3xl avgarde-thin'>SCOPRI LA TRADIZIONE</p>
                <i className='text-xl mt-2 animate-bounce fas fa-angle-double-down' />
            </div>



            <div style={{ backgroundImage: `url(${backgroundImg}` }} className='bg absolute inset-0 opacity-30 -z-10' />
            <div className='bg-black absolute inset-0 -z-20' />

            {/* GRADIENT BOTTOM */}
            <div className='absolute bottom-0 right-0 left-0 h-40 gradient-black-reverse -z-10' />
        </div>
    )
}

export default WelcomeSlide