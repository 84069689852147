import "./navbar.css"
import React, { useState } from 'react';
import logo from '../../assets/logo/logo_textless.svg'
import MobileSidebar from "../MobileSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";


const Navbar = (props) => {

    let routes = [
        {
            label: 'LA NOSTRA STORIA',
            ref: props.storyRef
        },
        {
            label: 'SERVIZI',
            ref: props.serviceRef
        },
        {
            label: 'CATALOGO',
            ref: props.catalogueRef
        },
        {
            label: 'CONTATTI',
            ref: props.contactRef
        }
    ]


    const [toggle, setToggle] = useState()

    const handleScroll = (ref) => () => {
        props.scrollTo({ ref: ref, duration: 1200 })
        setToggle(false)
    }


    const toggleSidebar = () => {
        setToggle(!toggle)
    }

    return (
        <div className='p-3 md:p-0 max-w-5xl text-white avgarde flex flex-row mx-auto z-20'>
            <div className='flex flex-row justify-center items-center z-20'>
                <img className='w-20 lg:w-24' src={logo} />
                <p className='text-2xl lg:text-3xl ml-4'>baldassarre</p>
            </div>

            <div className='hidden md:flex text-2xl lg:text-3xl flex-1 space-x-4  flex-row  justify-center items-center'>
                {routes.map((r,i) => <p key={i} onClick={handleScroll(r.ref)} className='link'>{r.label}</p>)}
                
            </div>

            <div className='md:hidden flex-1 flex justify-end items-center text-2xl text-white z-50 '>
                <FontAwesomeIcon 
                icon={faBars} 
                onClick={toggleSidebar}
                className='text-3xl mr-4'
                />
            </div>

            <MobileSidebar
                handleScroll={handleScroll}
                routes={routes}
                toggleSidebar={toggleSidebar}
                isOpened={toggle}
            />
        </div>
    )
};

export default Navbar;
