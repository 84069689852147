import "../Home.css"
import React, { forwardRef } from 'react'
import Services from '../../../components/Services/Services'
import Fade from "react-reveal/Fade"
import serviceBg from '../../../assets/imgs/photos/6.jpg'

const ServiceSlide = forwardRef((props, ref) => {

    return (
        <div ref={ref} className='h-screen relative flex flex-col justify-center items-center'>
            {/* GRADIENT TOP */}
            <div className='gradient-black h-40 absolute top-0 left-0 right-0' />
            {/* GRADIENT TOP */}


            <Fade>
                <p className='mt-14 md:mt-16 z-20 avgarde font-bold text-white text-4xl'>SERVIZI</p>
                <p className='flex flex-wrap max-w-lg text-white text-center mx-6 md:mx-0 text-md md:text-xl avgarde-thin'>
                    Da sempre cerchiamo di aiutare il cliente a 360 gradi,
                    per questo offriamo un vasto numero di servizi, dalla Vendita all'Assistenza.
                </p>
            </Fade>
            <Services />

            {/* GRADIENT BOTTOM */}
            <div className='absolute bottom-0 right-0 left-0 h-40 gradient-black-reverse z-10' />
            
            {/* BACKGROUND + OVERLAY */}
            <div style={{ backgroundImage: `url(${serviceBg}` }} className='bg absolute inset-0 opacity-30 -z-10' />
            <div className='bg-black absolute inset-0 -z-20' />
        </div>
    )
})

export default ServiceSlide